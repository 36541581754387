.footer-box {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    background-color: "#fff";
    position: relative;
    width: 100%;
    bottom: 0;
    margin-top: 3%;
    border-radius: 15px;
    padding-top: 25px
}

.copyright {
    font-size: .75rem;
    color: "#000";
    opacity: 75%;
    text-align: center;
    margin-top: 0px;
}

.terms {
    text-align: center;
    text-decoration: none;
}

.terms a {
    color: "#000";
    text-decoration: none;
}

.terms a:hover {
    color: black;
}